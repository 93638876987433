var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('div',{staticClass:"input-file input-file--loading"},[_c('div',[_c('figure',[_c('unicon',{attrs:{"width":"20px","height":"20px","name":"file-blank"}})],1),_vm._m(0)]),_c('icon-button',{staticClass:"btn-remove",attrs:{"icon":"times","label":"remover"},on:{"click":function($event){{
        _vm.file = null;
        _vm.$emit('removeFile');
      }}}})],1):(_vm.file)?_c('div',{staticClass:"input-file"},[_c('div',[_c('figure',[_c('unicon',{attrs:{"width":"20px","height":"20px","name":"file-blank"}})],1),_c('span',[_c('p',[_vm._v(_vm._s(_vm.fileName))]),_c('p',[_vm._v(_vm._s(_vm.data))])])]),_c('icon-button',{staticClass:"btn-remove",attrs:{"icon":"times","label":"remover"},on:{"click":function($event){{
        _vm.file = null;
        _vm.$emit('removeFile');
      }}}})],1):_c('div',{staticClass:"input-file input-file--emptystate",on:{"click":_vm.triggerInput}},[_c('input',{ref:"input-file",attrs:{"type":"file"},on:{"input":_vm.uploadFile}}),_c('div',[_c('figure',[_c('unicon',{attrs:{"width":"20px","height":"20px","name":"file-upload"}})],1),_c('span',[_c('p',[_vm._v(_vm._s(_vm.emptyState))])])]),_c('icon-button',{staticClass:"btn-remove",attrs:{"icon":"times","label":"remover"},on:{"click":function($event){{
        _vm.file = null;
        _vm.$emit('removeFile');
      }}}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('p',[_vm._v("Cargando...")])])}]

export { render, staticRenderFns }