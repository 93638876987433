<template>
  <div class="view__container">
    <div class="content__top">
      <div>
        <Breadcrumbs
          :views="[]"
          :currentView="{ label: 'Estructura Salarial Interna', icon: 'sitemap' }"
        />
        <h2>Estructura Salarial Interna</h2>
      </div>
      <div class="content__buttons">
        <Button @click="openConfigModal" size="small">
          <unicon name="cog" fill="currentColor" width="16px" height="16px" />
          Configuración
        </Button>
        <Menu direction="left">
          <template #label>
            <Button type="button" variant="secondary" size="xsmall">
              <unicon
                class="ellipsis"
                name="ellipsis-h"
                fill="currentColor"
                height="16px"
                width="16px"
              />
            </Button>
          </template>
          <template #options>
            <menu-item @click="openExportModal">
              <unicon name="file-upload" fill="currentColor" height="16px" width="16px"></unicon>
              Exportar
            </menu-item>
          </template>
        </Menu>
      </div>
    </div>
    <div class="filter__container">
      <Menu direction="below" :closeOnItemClick="false">
        <template #label>
          <filter-item :filter="incomeFilter" />
        </template>
        <template #options>
          <menu-item
            v-for="option in [...incomeFilter.options]"
            @click="
              option.active = !option.active;
              filterEmployees();
            "
            :key="option.id"
          >
            {{ option.name }}
            <unicon v-if="option.active" width="16px" height="16px" name="check" fill=""></unicon>
          </menu-item>
        </template>
      </Menu>
      <Filters
        @filter="
          (activeFilters) => {
            filters = activeFilters;
            filterEmployees(activeFilters);
          }
        "
        :filters="[...defaultCategories.filter(({ id }) => id == 'gender'), ...categories]"
        :filtersOptions="[
          ...defaultCategoriesOptions.filter(({ categoryId }) => categoryId == 'gender'),
          ...options,
        ]"
        ref="filters"
      />
    </div>
    <Tabs
      v-if="!isLoading"
      :tabList="[
        { title: 'Puestos', icon: 'table' },
        { title: 'Dashboard', icon: 'apps' },
      ]"
    >
      <template v-slot:tabPanel-1>
        <div class="content">
          <table ref="table">
            <tr>
              <th class="number">#</th>
              <th>Puesto</th>
              <th>Empleados</th>
              <th>Mínimo</th>
              <th>Media</th>
              <th>Mediana</th>
              <th>Superior</th>
              <th>Estadísticas</th>
              <th></th>
            </tr>
            <template v-if="positions.length && !isLoading">
              <tr
                v-for="(position, index) in positions.filter(({ id }) =>
                  Object.keys(meanAndMedians.means).includes(id),
                )"
                :key="position.id"
              >
                <td class="number">{{ index + 1 }}</td>
                <td>{{ position.name }}</td>
                <td>
                  {{ filteredEmployees.filter(({ puesto }) => puesto === position.id).length }}
                </td>
                <td>
                  {{
                    (position.payEquity && position.payEquity.min && position.payEquity.min.isActive
                      ? position.payEquity.min.value
                      : (meanAndMedians.medians[position.id] * company.salaryEquityCongif.min) /
                          100 || 0
                    ).toLocaleString('en', {
                      useGrouping: true,
                      maximumFractionDigits: 0,
                    })
                  }}
                </td>
                <template v-if="meanAndMedians.medians[position.id]">
                  <td>
                    {{
                      (position.payEquity &&
                      position.payEquity.mean &&
                      position.payEquity.mean.isActive
                        ? position.payEquity.mean.value
                        : meanAndMedians.means[position.id]
                      ).toLocaleString('en', {
                        useGrouping: true,
                        maximumFractionDigits: 0,
                      })
                    }}
                  </td>

                  <td>
                    {{
                      (position.payEquity &&
                      position.payEquity.median &&
                      position.payEquity.median.isActive
                        ? position.payEquity.median.value
                        : meanAndMedians.medians[position.id]
                      ).toLocaleString('en', {
                        useGrouping: true,
                        maximumFractionDigits: 0,
                      })
                    }}
                  </td>
                </template>

                <template v-else>
                  <td>0.00</td>
                  <td>0.00</td>
                </template>
                <td>
                  {{
                    (position.payEquity && position.payEquity.max && position.payEquity.max.isActive
                      ? position.payEquity.max.value
                      : (meanAndMedians.medians[position.id] * company.salaryEquityCongif.max) /
                          100 || 0
                    ).toLocaleString('en', {
                      useGrouping: true,
                      maximumFractionDigits: 0,
                    })
                  }}
                </td>
                <td class="stats-button" @click="openStatsModal(position)">
                  STAT
                  <button
                    class="stats-button"
                    title="Ver estadísticas"
                    @click="openStatsModal(position)"
                  >
                    <unicon name="" height="16.8" width="16.8" fill="currentColor" />
                  </button>
                </td>
                <td class="table-action" @click="redirectToEmployees(position.id)">
                  Ver empleados
                </td>
              </tr>
            </template>
          </table>
          <div v-if="isLoading" class="loading"><loading-spinner /></div>
        </div>
      </template>
      <template v-slot:tabPanel-2>
        <div class="content">
          <Dashboard :employees="filteredEmployees" ref="dashboard" />
        </div>
      </template>
    </Tabs>

    <ConfigurationModal ref="modal__config" @update="filterEmployees" />
    <StatsModal ref="modal__stats" />
    <export-modal
      ref="modal__export"
      @export="onExport($event)"
      :formats="{
        excel: {
          id: 'excel',
          name: 'Excel',
          options: [{ id: 'positions', name: 'Puestos' }],
        },
        ppt: {
          id: 'ppt',
          name: 'PPT',
          options: [{ id: 'dashboard', name: 'Dashboard' }],
        },
      }"
    />
  </div>
</template>

<script>
/* eslint-disable */
import { mapMutations, mapActions, mapState } from 'vuex';

import { mean, median } from 'mathjs';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Filters from '@/components/filters/Filters.vue';
import Menu from '@/components/menu/Menu.vue';
import MenuItem from '@/components/menu/MenuItem.vue';
import FilterItem from '@/components/filters/FilterItem.vue';
import Button from '@/components/buttons/Button.vue';
import LoadingSpinner from '@/components/loading/LoadingSpinner.vue';
import Tabs from '@/components/Tabs.vue';
import ExportModal from '@/components/ExportModal.vue';
import DEFAULT_CATEGORIES from '@/defaultCategories';
import pptxgen from 'pptxgenjs';
import * as XLSX from 'xlsx';
import * as XLSXS from 'xlsx-style';
import ConfigurationModal from './modals/ConfigurationModal.vue';
import StatsModal from './modals/StatsModal.vue';
import Dashboard from './Dashboard.vue';

export default {
  components: {
    Breadcrumbs,
    Menu,
    MenuItem,
    FilterItem,
    Filters,
    Button,
    ConfigurationModal,
    StatsModal,
    LoadingSpinner,
    Tabs,
    Dashboard,
    ExportModal,
  },
  data() {
    return {
      mean,
      median,
      isLoading: false,
      filteredEmployees: [],
      filters: [],
      incomeFilter: {
        id: 'incomeId',
        name: 'Ingresos',
        options: [
          {
            id: 'miniumWage',
            name: 'Sueldo base',
            active: true,
            index: 0,
          },
          {
            id: 'otherIncomes',
            name: 'Otros ingresos',
            active: false,
            index: 1,
          },
        ],
      },
      defaultCategories: DEFAULT_CATEGORIES.CATEGORIES,
      defaultCategoriesOptions: DEFAULT_CATEGORIES.OPTIONS,
    };
  },
  computed: {
    ...mapState('jobEvaluation', {
      positions: (state) => state.positions,
    }),
    ...mapState({
      employees: (state) => state.employees.employees,
      categories: (state) => state.categories.categories,
      options: (state) => state.options.options,
    }),
    ...mapState({
      company: (state) => state.company,
    }),
    meanAndMedians() {
      const medians = {};
      const means = {};
      const salariesByPosition = {};
      this.filteredEmployees.forEach((employee) => {
        if (!salariesByPosition[employee.puesto]) salariesByPosition[employee.puesto] = [];
        salariesByPosition[employee.puesto].push(employee.salary);
      });
      Object.keys(salariesByPosition).forEach((position) => {
        medians[position] = this.median(salariesByPosition[position]);
        means[position] = this.mean(salariesByPosition[position]);
      });
      return { medians, means };
    },
  },

  methods: {
    ...mapActions('jobEvaluation', ['getPositions']),
    ...mapActions('employees', ['fetchEmployees']),
    ...mapActions('categories', ['fetchCategories']),
    ...mapActions('options', ['fetchOptions']),
    ...mapMutations(['setAlert']),
    ...mapActions(['updateCompany']),

    openExportModal() {
      this.$refs.modal__export.open();
    },
    onExport(exportConfig) {
      if (exportConfig.format === 'ppt') this.downloadPPT();
      if (exportConfig.format === 'excel') this.downloadExcel();
    },
    downloadPPT() {
      const chartsData = [
        this.$refs.dashboard.statusDataset,
        this.$refs.dashboard.status2Dataset,
        this.$refs.dashboard.status3Dataset,
      ];
      const chartsNames = ['Status', 'Status mediana', '%<% Mediana'];

      /* eslint-disable */
      const pptx = new pptxgen();
      const config = {
        x: 1,
        // y: 1.2,
        y: 0.31,
        w: 8,
        // h: 4.3,
        h: 5,
        catAxisLabelColor: '000000',
        catAxisLabelFontSize: 8,
        valAxisLabelFontSize: 8,
        dataLabelFontSize: 8,
        legendFontSize: 8,
        titleFontSize: 10,
        chartColors: ['#74d99f', '#72bffd', '#ea7c7b'],
        dataLabelPosition: 't',
        dataLabelFormatCode: '#%',
        valAxisLabelFormatCode: '#%',
        showValue: true,
        showTitle: true,
        showLegend: true,
        legendPos: 't',
        valAxisMaxVal: 1,
      };

      const slide = pptx.addSlide();

      slide.addText('Estructura Salarial Interna', {
        x: 0,
        y: 2.61,
        h: 0.4,
        w: '100%',
        align: 'center',
        fontSize: 32,
      });

      let text = '';
      this.$refs.filters.activeFilters.forEach((filter, i) => {
        let options = filter.options.filter((option) => option.active);
        text += options.length ? ' / ' + filter.name + ': ' : '';
        options.forEach((option, index) => {
          text += option.name + (index == options.length - 1 ? '' : ', ');
        });
        text += text.length && i === this.$refs.filters.activeFilters.length - 1 ? ' /' : '';
      });
      slide.addText(text, {
        x: 0,
        y: 3.1,
        h: 0.4,
        w: '100%',
        align: 'center',
        fontSize: 14,
      });

      chartsData.forEach((chart, index) => {
        const slide = pptx.addSlide();
        const chartData = [];
        chart.datasets.forEach((data, i) => {
          chartData.push({
            labels: chart.labels.map((val) => (Array.isArray(val) ? val.join(' ') : val)),
            values: data.data.map((val) => val * this.filteredEmployees.length),
          });
        });
        slide.addChart(pptx.ChartType.doughnut, chartData, {
          ...config,
          title: `${chartsNames[index]}`,
        });
      });

      const newSlide = pptx.addSlide();
      newSlide.addChart(
        pptx.ChartType.scatter,
        [
          { name: 'Salario', values: this.$refs.dashboard.datasets[0].data.map(({ x }) => x) },
          {
            name: 'Años en la empresa',
            values: this.$refs.dashboard.datasets[0].data.map(({ y }) => y),
          },
        ],
        {
          title: 'Salario x Años en la empresa',
          showTitle: true,
          titleFontSize: 10,
          valAxisTitle: 'Años en la empresa',
          showValAxisTitle: true,
          lineSize: 0,
          catAxisTitle: 'Salario',
          showCatAxisTitle: true,
          showLabel: true, // Must be set to true or labels will not be shown
          dataLabelPosition: 'b', // Options: 't'|'b'|'l'|'r'|'ctr'
          x: 1,
          y: 0.31,
          w: 8,
          h: 5,
          chartColors: this.company.colors,
        },
      );

      pptx.writeFile({ fileName: 'dashboard.pptx' });
    },
    saveBlobAsFile(name, blob) {
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    },

    s2ab(s) {
      let buf = new ArrayBuffer(s.length);
      let view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    },
    downloadExcel() {
      const sheet = XLSX.utils.table_to_sheet(this.$refs.table);
      sheet['!ref'] = `A1:F${this.positions.length + 1}`;

      const workbook = {
        SheetNames: ['Posiciones'],
        Sheets: { Posiciones: sheet },
      };

      workbook.SheetNames.forEach((sheetName) => {
        const ws = workbook.Sheets[sheetName];
        const range = XLSX.utils.decode_range(ws['!ref']);

        for (let C = range.s.c; C <= range.e.c; ++C) {
          const address = XLSX.utils.encode_cell({ r: 0, c: C });
          if (!ws[address]) continue;
          ws[address].s = { fill: { fgColor: { rgb: '1A96FC' } } };
        }
      });

      const workbookout = XLSXS.write(workbook, {
        bookType: 'xlsx',
        type: 'binary',
      });

      this.saveBlobAsFile(
        `posiciones.xlsx`,
        new Blob([this.s2ab(workbookout)], { type: 'application/octet-stream' }),
      );
    },
    redirectToEmployees(positionId) {
      this.$router.push({
        name: 'PositionEmployees',
        params: {
          positionId,
        },
      });
    },
    openConfigModal() {
      this.$refs.modal__config.open();
    },

    openStatsModal(position) {
      this.$refs.modal__stats.open(
        this.filteredEmployees
          .filter(({ puesto }) => puesto === position.id)
          .map(({ salary }) => salary),
      );
    },

    filterEmployees(filters = []) {
      const activeFilters = [];
      const employees = JSON.parse(JSON.stringify(this.employees));
      filters.forEach((filter) => {
        const activeOptions = filter.options
          .filter((option) => option.active)
          .map((option) => option.id);

        if (activeOptions.length) {
          activeFilters.push({
            id: filter.id,
            type: filter.type,
            options: activeOptions,
          });
        }
      });

      this.filteredEmployees = employees.filter((employee) => {
        for (let index = 0; index < activeFilters.length; index += 1) {
          const filter = activeFilters[index];
          if (!filter.options.includes(employee[filter.id])) {
            return false;
          }
        }
        return true;
      });
      this.filteredEmployees.forEach((employee, index) => {
        const mult = this.company.salaryEquityCongif.multiplier || 1;
        const salary = mult * this.getTotalIncome(employee.miniumWage, employee.otherIncomes);
        this.filteredEmployees[index].salary = salary;
      });
    },

    getTotalIncome(miniumWage, otherIncomes) {
      const accOtherIncomes = otherIncomes.reduce((acc, val) => acc + val, 0);
      const activeIncomes = this.incomeFilter.options
        .filter(({ active }) => active)
        .map(({ id }) => id);
      if (activeIncomes.length === 0) return 0;
      if (activeIncomes.length === 2) return accOtherIncomes + miniumWage;
      if (activeIncomes[0] === 'miniumWage') return miniumWage;
      return accOtherIncomes;
    },
  },

  async mounted() {
    try {
      this.isLoading = true;
      if (!this.positions.length) await this.getPositions();
      if (!this.employees.length) await this.fetchEmployees();
      if (!this.categories.length) await this.fetchCategories();
      if (!this.options.length) await this.fetchOptions();
      this.filterEmployees();
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  flex-grow: 1;
  height: calc(100% - 40.8px - 26.55px - 1rem - 0.3rem - 35px - 1rem);
}
.view__container {
  border-radius: 10px;
  background-color: white;
  height: 100%;

  .content__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1rem;
  }

  .content__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
  }
}
.filter__container {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
  align-items: center;
  flex-wrap: wrap;
}
.content {
  width: 100%;
  overflow: auto;
  background-color: white;
  height: 100%;
}
table {
  width: 100%;

  .table-action {
    cursor: pointer;
    color: var(--main-color-500);
    &:hover {
      text-decoration: underline var(--main-color-500);
    }
  }
}

.stats-button {
  color: var(--success-color-500);
  transition: color 300ms;
  cursor: pointer;

  &:hover {
    color: var(--success-color-400);
  }
}
</style>
