<template>
  <div class="dashboard__container">
    <div>
      <h4>Status</h4>
      <doughnut-chart
        class="chart"
        :chart-data="statusDataset"
        :chart-options="{
          cutout: 0,
          radius: '95%',
          plugins: {
            legend: {
              position: 'bottom',
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  return [
                    `Valor: ${Math.round(
                      statusDataset.datasets[0].data[context.dataIndex] * employees.length,
                    )}`,
                  ];
                },
              },
            },
          },
        }"
      />
    </div>
    <div>
      <h4>Status mediana</h4>
      <doughnut-chart
        class="chart"
        :chart-data="status2Dataset"
        :chart-options="{
          cutout: 0,
          radius: '95%',
          plugins: {
            legend: {
              position: 'bottom',
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  return [
                    `Valor: ${Math.round(
                      status2Dataset.datasets[0].data[context.dataIndex] * employees.length,
                    )}`,
                  ];
                },
              },
            },
          },
        }"
      />
    </div>
    <div>
      <h4>%&lt;% Mediana</h4>
      <doughnut-chart
        class="chart"
        :chart-data="status3Dataset"
        :chart-options="{
          cutout: 0,
          radius: '95%',
          plugins: {
            legend: {
              position: 'bottom',
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  return [
                    `Valor: ${Math.round(
                      status3Dataset.datasets[0].data[context.dataIndex] * employees.length,
                    )}`,
                  ];
                },
              },
            },
          },
        }"
      />
    </div>
    <div>
      <h4>Salario x Años en la empresa</h4>
      <scatter-chart
        class="chart"
        :chartData="{ datasets }"
        :chartOptions="{
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: 'Salario',
                font: {
                  size: 12,
                  style: 'normal',
                },
                padding: { top: 10, left: 0, right: 0, bottom: 0 },
              },
            },
            y: {
              display: true,
              title: {
                display: true,
                text: 'Años en la empresa',
                font: {
                  size: 12,
                  style: 'normal',
                },
                padding: { top: 0, left: 0, right: 0, bottom: 10 },
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            datalabels: {
              display: false,
            },
          },
        }"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mean, median } from 'mathjs';
import ScatterChart from '@/components/charts/ScatterChart.vue';
import DoughnutChart from '@/components/charts/DoughnutChart.vue';
import { timeStampToAge } from '@/dateFormats';

export default {
  components: {
    ScatterChart,
    DoughnutChart,
  },
  props: {
    employees: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      mean,
      median,
      timeStampToAge,
    };
  },
  methods: {
    getPosition(positionId) {
      return this.positions.find(({ id }) => id === positionId);
    },
    getStatus(salary, positionId) {
      const { payEquity } = this.getPosition(positionId);
      const isMedianActive = payEquity && payEquity.median && payEquity.median.isActive;
      const medians = isMedianActive
        ? payEquity.median.value
        : this.meanAndMedians.medians[positionId];
      if (salary > medians * (this.company.salaryEquityCongif.max / 100)) return 0;
      if (salary < medians * (this.company.salaryEquityCongif.min / 100)) return 2;
      return 1;
    },

    getStatus2(salary, positionId) {
      const { payEquity } = this.getPosition(positionId);
      const isMedianActive = payEquity && payEquity.median && payEquity.median.isActive;
      const medians = isMedianActive
        ? payEquity.median.value
        : this.meanAndMedians.medians[positionId];
      if (salary > medians) return 0;
      if (salary < medians) return 2;
      return 1;
    },

    getStatus3(salary, positionId) {
      const { payEquity } = this.getPosition(positionId);
      const isMedianActive = payEquity && payEquity.median && payEquity.median.isActive;
      const medians = isMedianActive
        ? payEquity.median.value
        : this.meanAndMedians.medians[positionId];
      if (salary > medians * (this.company.salaryEquityCongif.posMax / 100)) return 0;
      if (salary < medians * (this.company.salaryEquityCongif.posMin / 100)) return 2;
      return 1;
    },
  },
  computed: {
    ...mapState({
      company: (state) => state.company,
      categories: (state) => state.categories.categories,
      options: (state) => state.options.options,
    }),
    ...mapState('jobEvaluation', {
      positions: (state) => state.positions,
    }),

    meanAndMedians() {
      const medians = {};
      const means = {};
      const salariesByPosition = {};
      this.employees.forEach((employee) => {
        if (!salariesByPosition[employee.puesto]) salariesByPosition[employee.puesto] = [];
        salariesByPosition[employee.puesto].push(employee.salary);
      });
      Object.keys(salariesByPosition).forEach((position) => {
        medians[position] = this.median(salariesByPosition[position]);
        means[position] = this.mean(salariesByPosition[position]);
      });
      return { medians, means };
    },
    employeesEntryDateXSalary() {
      const data = [];
      this.employees.forEach(({ salary, entryDate }) => {
        data.push({ x: salary, y: this.timeStampToAge(entryDate) });
      });
      return data;
    },

    datasets() {
      return [
        {
          label: 'Empleados',
          fill: false,
          borderColor: this.company.colors[0],
          backgroundColor: this.company.colors[0],
          data: this.employeesEntryDateXSalary,
        },
      ];
    },

    statusDataset() {
      const data = [0, 0, 0];
      this.employees.forEach(({ salary, puesto }) => {
        data[this.getStatus(salary, puesto)] += 1;
      });
      return {
        labels: ['Por encima', 'Dentro', 'Por debajo'],
        datasets: [
          {
            backgroundColor: ['#74d99f', '#72bffd', '#ea7c7b'],
            data: data.map((val) => val / (data[0] + data[1] + data[2])),
          },
        ],
      };
    },

    status2Dataset() {
      const data = [0, 0, 0];
      this.employees.forEach(({ salary, puesto }) => {
        data[this.getStatus2(salary, puesto)] += 1;
      });
      return {
        labels: ['Por encima', 'Dentro', 'Por debajo'],
        datasets: [
          {
            backgroundColor: ['#74d99f', '#72bffd', '#ea7c7b'],
            data: data.map((val) => val / (data[0] + data[1] + data[2])),
          },
        ],
      };
    },

    status3Dataset() {
      const data = [0, 0, 0];
      this.employees.forEach(({ salary, puesto }) => {
        data[this.getStatus3(salary, puesto)] += 1;
      });
      return {
        labels: ['Por encima', 'Dentro', 'Por debajo'],
        datasets: [
          {
            backgroundColor: ['#74d99f', '#72bffd', '#ea7c7b'],
            data: data.map((val) => val / (data[0] + data[1] + data[2])),
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard__container {
  overflow: auto;
  gap: 1rem;
  display: grid;
  grid-template-areas:
    'd1 d2 d3'
    's1 s1 s1';
  grid-template-rows: 20rem 25rem;
  grid-template-columns:
    calc(33.3% - 1rem)
    calc(33.3% - 1rem)
    calc(33.3% - 1rem);

  & > div {
    height: 100%;
    display: flex;
    flex-flow: column;
    gap: 1rem;
    border: solid 1px var(--gray-color-500);
    border-radius: 3px;
    padding: 1rem;
    & > *:nth-child(2) {
      flex-grow: 1;
    }
    &:nth-child(1) {
      grid-area: d1;
    }
    &:nth-child(2) {
      grid-area: d2;
    }
    &:nth-child(3) {
      grid-area: d3;
    }
    &:nth-child(4) {
      grid-area: s1;
    }
  }
}
</style>
