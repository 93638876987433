<template>
  <div v-if="isLoading" class="input-file input-file--loading">
    <div>
      <figure>
        <unicon width="20px" height="20px" name="file-blank"></unicon>
      </figure>
      <span>
        <p>Cargando...</p>
      </span>
    </div>
    <icon-button
      class="btn-remove"
      icon="times"
      @click="
        {
          file = null;
          $emit('removeFile');
        }
      "
      label="remover"
    />
  </div>
  <div v-else-if="file" class="input-file">
    <div>
      <figure>
        <unicon width="20px" height="20px" name="file-blank"></unicon>
      </figure>
      <span>
        <p>{{ fileName }}</p>
        <p>{{ data }}</p>
      </span>
    </div>

    <icon-button
      class="btn-remove"
      icon="times"
      @click="
        {
          file = null;
          $emit('removeFile');
        }
      "
      label="remover"
    />
  </div>
  <div v-else class="input-file input-file--emptystate" @click="triggerInput">
    <input ref="input-file" type="file" @input="uploadFile" />
    <div>
      <figure>
        <unicon width="20px" height="20px" name="file-upload"></unicon>
      </figure>
      <span>
        <p>{{ emptyState }}</p>
      </span>
    </div>
    <icon-button
      class="btn-remove"
      icon="times"
      @click="
        {
          file = null;
          $emit('removeFile');
        }
      "
      label="remover"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import IconButton from '@/components/buttons/IconButton.vue';

export default {
  name: 'FileInput',
  components: {
    IconButton,
  },

  data() {
    return { file: null, fileName: '' };
  },

  props: {
    data: {
      type: String,
    },
    isLoading: {
      default: false,
      type: Boolean,
    },
    emptyState: {
      default: 'Seleccionar archivo CSV',
      type: String,
    },
  },

  methods: {
    ...mapMutations(['setAlert']),

    triggerInput() {
      this.$refs['input-file'].click();
    },

    getFileExtension(filename) {
      return filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename;
    },

    uploadFile(e) {
      if (this.getFileExtension(e.target.files[0].name) !== 'csv' && e.target.files[0].type !== 'text/csv') {
        this.setAlert({
          state: 'error',
          message: 'El tipo de archivo debe ser CSV',
        });
        return;
      }
      [this.file] = e.target.files;

      this.fileName = this.file.name;
      this.$emit('uploadFile', this.file);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem 1rem;
  border-radius: 5px;
  border: solid 1px var(--gray-color-400);
  p {
    line-height: 0.8rem;
    font-size: 0.75rem;
    color: var(--font-color-700);
    font-weight: var(--regular);
  }
  div {
    display: flex;
    gap: 1rem;
    figure {
      background-color: var(--main-color-100);
      display: flex;
      place-items: center;
      padding: 0.3rem;
      border-radius: 5px;
      .unicon {
        fill: var(--main-color-500);
      }
    }
    span {
      display: flex;
      flex-flow: column;
      gap: 0.1rem;
      justify-content: center;
      p {
        &:first-child {
          font-weight: var(--semi-bold);
        }
        &:nth-child(2) {
          font-size: 0.65rem;
        }
      }
    }
  }

  &--emptystate {
    position: relative;
    border: dashed 1px var(--gray-color-400);
    cursor: pointer;
    input {
      opacity: 0;
      width: 0;
      height: 0;
      position: absolute;
    }
    p {
      color: var(--font-color-200);
      font-size: 0.8rem;
    }
    div {
      align-items: center;
      figure {
        background: none;
        .unicon {
          fill: var(--font-color-200);
        }
      }
    }
    .btn-remove {
      display: none;
    }
    &:hover {
      background-color: var(--gray-color-100);
    }
  }

  &--loading {
    cursor: wait;
    div {
      align-items: center;
      p {
        color: var(--font-color-200);
      }
      figure {
        background: var(--gray-color-100);
        .unicon {
          fill: var(--font-color-200);
        }
      }
    }
    .btn-remove {
      display: none;
    }
  }
}
</style>
