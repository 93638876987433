var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard__container"},[_c('div',[_c('h4',[_vm._v("Status")]),_c('doughnut-chart',{staticClass:"chart",attrs:{"chart-data":_vm.statusDataset,"chart-options":{
        cutout: 0,
        radius: '95%',
        plugins: {
          legend: {
            position: 'bottom',
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                return [
                  ("Valor: " + (Math.round(
                    _vm.statusDataset.datasets[0].data[context.dataIndex] * _vm.employees.length
                  ))) ];
              },
            },
          },
        },
      }}})],1),_c('div',[_c('h4',[_vm._v("Status mediana")]),_c('doughnut-chart',{staticClass:"chart",attrs:{"chart-data":_vm.status2Dataset,"chart-options":{
        cutout: 0,
        radius: '95%',
        plugins: {
          legend: {
            position: 'bottom',
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                return [
                  ("Valor: " + (Math.round(
                    _vm.status2Dataset.datasets[0].data[context.dataIndex] * _vm.employees.length
                  ))) ];
              },
            },
          },
        },
      }}})],1),_c('div',[_c('h4',[_vm._v("%<% Mediana")]),_c('doughnut-chart',{staticClass:"chart",attrs:{"chart-data":_vm.status3Dataset,"chart-options":{
        cutout: 0,
        radius: '95%',
        plugins: {
          legend: {
            position: 'bottom',
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                return [
                  ("Valor: " + (Math.round(
                    _vm.status3Dataset.datasets[0].data[context.dataIndex] * _vm.employees.length
                  ))) ];
              },
            },
          },
        },
      }}})],1),_c('div',[_c('h4',[_vm._v("Salario x Años en la empresa")]),_c('scatter-chart',{staticClass:"chart",attrs:{"chartData":{ datasets: _vm.datasets },"chartOptions":{
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            display: true,
            title: {
              display: true,
              text: 'Salario',
              font: {
                size: 12,
                style: 'normal',
              },
              padding: { top: 10, left: 0, right: 0, bottom: 0 },
            },
          },
          y: {
            display: true,
            title: {
              display: true,
              text: 'Años en la empresa',
              font: {
                size: 12,
                style: 'normal',
              },
              padding: { top: 0, left: 0, right: 0, bottom: 10 },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
      }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }