<template>
  <validation-observer tag="div" v-slot="{}">
    <form class="form__content">
      <custom-select
        v-model="positionId"
        :options="positions"
        label="Puestos"
        rules="required"
        icon="suitcase"
        @change="setUpPositionsInput"
      />

      <div class="form__options" v-if="Object.keys(position).length">
        <div class="form__option">
          <span>
            <span><switch-component v-model="position.payEquity.max.isActive" /></span>
            <custom-input
              v-model="position.payEquity.max.value"
              label="Superior"
              type="number"
              rules="required"
              icon="none"
              :disabled="!position.payEquity.max.isActive"
            />
          </span>
          <span>
            <span><switch-component v-model="position.payEquity.min.isActive" /></span>
            <custom-input
              v-model="position.payEquity.min.value"
              label="Mínimo"
              type="number"
              rules="required"
              icon="none"
              :disabled="!position.payEquity.min.isActive"
            />
          </span>
        </div>
        <div class="form__option">
          <span>
            <span><switch-component v-model="position.payEquity.mean.isActive" /></span>
            <custom-input
              v-model="position.payEquity.mean.value"
              label="Media"
              type="number"
              rules="required"
              icon="none"
              :disabled="!position.payEquity.mean.isActive"
            />
          </span>
          <span>
            <span><switch-component v-model="position.payEquity.median.isActive" /></span>
            <custom-input
              v-model="position.payEquity.median.value"
              label="Mediana"
              type="number"
              rules="required"
              icon="none"
              :disabled="!position.payEquity.median.isActive"
            />
          </span>
        </div>
      </div>

      <file-input
        :isLoading="isUploadingFile"
        @uploadFile="(file) => parseFile(file)"
        :data="`${filePositions ? Object.keys(filePositions).length : 0} posicion(es)`"
        @removeFile="filePositions = null"
      />
    </form>
  </validation-observer>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import { ValidationObserver } from 'vee-validate';

import Papa from 'papaparse';
import CustomSelect from '@/components/custom/CustomSelect.vue';
import CustomInput from '@/components/custom/CustomInput.vue';
import SwitchComponent from '@/components/Switch.vue';
import FileInput from '@/components/custom/FileInput.vue';

export default {
  components: {
    ValidationObserver,
    CustomSelect,
    CustomInput,
    SwitchComponent,
    FileInput,
  },
  data() {
    return {
      positionId: null,
      position: {},
      isUploadingFile: false,
      filePositions: null,
    };
  },
  methods: {
    ...mapMutations(['setAlert']),
    ...mapActions('options', ['updateOption', 'updateOptionsBatch']),
    ...mapActions('jobEvaluation', ['getPositions']),

    setUpPositionsInput() {
      const pos = this.positions.find(({ id }) => this.positionId === id);
      // if (pos) return pos;
      if (pos) this.position = JSON.parse(JSON.stringify(pos));

      if (!this.position.payEquity) {
        this.position.payEquity = {
          min: { value: 0, isActive: true },
          max: { value: 0, isActive: true },
          mean: { value: 0, isActive: true },
          median: { value: 0, isActive: true },
        };
      }
    },

    async onSave() {
      try {
        if (this.filePositions) {
          const ids = Object.keys(this.filePositions);
          this.positions.forEach((position, index) => {
            this.positions[index] = {
              ...position,
              ...(this.filePositions[position.id] && {
                payEquity: this.filePositions[position.id],
              }),
            };
          });
          await this.updateOptionsBatch(this.positions.filter(({ id }) => ids.includes(id)));
        } else {
          await this.updateOption(this.position);
          this.positions
            .find(({ id }) => this.positionId === id).payEquity = this.position.payEquity;
        }
        this.$emit('close');
        this.setAlert({
          state: 'success',
          message: 'Empresa actualizada',
        });
      } catch (e) {
        console.log(e);
        this.setAlert({
          state: 'error',
          message: 'Ocurrió un error, por favor inténtelo nuevamente',
        });
      }
    },

    parseFile(file) {
      if (!file) return;
      this.isUploadingFile = true;

      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        transformHeader: (header) => {
          switch (header.toLowerCase()) {
            case 'id':
              return 'id';
            case 'nombre':
              return 'name';
            case 'minimo':
              return 'min';
            case 'superior':
              return 'max';
            case 'media':
              return 'mean';
            case 'mediana':
              return 'median';
            default:
              return header.trim();
          }
        },
        transform: (value) => value.trim(),

        complete: (results) => {
          if (!results) return;
          this.filePositions = {};
          results.data.forEach(({ id, name, ...measures }) => {
            this.filePositions[id] = {
              ...Object.keys(measures).reduce(
                (acc, current) => ({
                  ...acc,
                  [current]: { isActive: true, value: parseInt(measures[current], 0) },
                }),
                {},
              ),
            };
          });
          this.isUploadingFile = false;
        },
      });
    },
  },

  computed: {
    ...mapState({}),
    ...mapState('jobEvaluation', {
      positions: (state) => state.positions,
    }),
  },

  async mounted() {
    try {
      await this.getPositions();
      this.positionId = this.positions[0].id;
      this.setUpPositionsInput();
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style lang="scss" scoped>
.form__content {
  display: flex;
  flex-flow: column;
  gap: 1rem;

  .form__options {
    display: flex;
    flex-flow: column;
    gap: 1rem;
    .form__option {
      display: flex;
      gap: 0.5rem;
      & > span {
        display: flex;
        position: relative;
        & > span:first-child {
          position: absolute;
          z-index: var(--index-3);
          top: 50%;
          transform: translateY(-50%);
          padding: 0.6rem;
        }
      }
    }
  }
}
</style>
