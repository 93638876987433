var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{ref:"modal__config",attrs:{"size":"sm"},on:{"close":function($event){_vm.configOptions = Object.assign({}, _vm.company.salaryEquityCongif)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Configuración")]},proxy:true},{key:"content",fn:function(){return [_c('validation-observer',{staticClass:"form__validation",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"tabs__container"},[_c('Tabs',{attrs:{"tabList":[
            { title: 'Data', icon: 'percentage' },
            { title: 'Puestos', icon: 'suitcase' },
            { title: 'Empleados', icon: 'user' } ]},on:{"tabChange":function($event){_vm.currentTab = $event}},scopedSlots:_vm._u([{key:"tabPanel-1",fn:function(){return [_c('form',[_c('div',{staticClass:"form__content"},[_c('div',[_c('span',[_c('h5',[_vm._v("Superior")]),_c('p',[_vm._v("Por encima de la estructura salarial interna.")])]),_c('span',[_c('UnitInput',{attrs:{"unit":"%","name":"Superior","rules":{
                        required: true,
                      },"value":_vm.configOptions.max},on:{"input":function($event){_vm.configOptions.max = $event}}})],1)]),_c('div',[_c('span',[_c('h5',[_vm._v("Mínimo")]),_c('p',[_vm._v("Por debajo de la estructura salarial interna.")])]),_c('span',[_c('UnitInput',{attrs:{"unit":"%","name":"Mínimo","rules":{
                        required: true,
                      },"value":_vm.configOptions.min},on:{"input":function($event){_vm.configOptions.min = $event}}})],1)]),_c('div',[_c('span',[_c('h5',[_vm._v("Múltiplo")]),_c('p',[_vm._v(" Multiplica la cantidad de sueldo a visualizar. ")])]),_c('span',[_c('custom-input',{attrs:{"type":"number","rules":"required"},model:{value:(_vm.configOptions.multiplier),callback:function ($$v) {_vm.$set(_vm.configOptions, "multiplier", $$v)},expression:"configOptions.multiplier"}})],1)])])])]},proxy:true},{key:"tabPanel-2",fn:function(){return [_c('UpdatePositionModal',{ref:"modal__position",on:{"close":_vm.closeModal}})]},proxy:true},{key:"tabPanel-3",fn:function(){return [_c('form',[_c('div',{staticClass:"form__content"},[_c('div',[_c('span',[_c('h5',[_vm._v("Superior")]),_c('p',[_vm._v("Porcentaje que está por encima de la mediana.")])]),_c('span',[_c('UnitInput',{attrs:{"unit":"%","name":"Superior","rules":{
                        required: true,
                      },"value":_vm.configOptions.posMax},on:{"input":function($event){_vm.configOptions.posMax = $event}}})],1)]),_c('div',[_c('span',[_c('h5',[_vm._v("Mínimo")]),_c('p',[_vm._v("Porcentaje que está por debajo de la mediana.")])]),_c('span',[_c('UnitInput',{attrs:{"unit":"%","name":"Mínimo","rules":{
                        required: true,
                      },"value":_vm.configOptions.posMin},on:{"input":function($event){_vm.configOptions.posMin = $event}}})],1)])])])]},proxy:true}],null,true)})],1),_c('div',{staticClass:"modal__buttons"},[_c('Button',{attrs:{"type":"submit","disabled":invalid,"size":"medium","variant":"primary"},on:{"click":_vm.onSave}},[_vm._v(" "+_vm._s(_vm.isLoading ? 'Guardando...' : 'Guardar')+" ")])],1)]}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }