<template>
  <div class="switch-container">
    <label class="switch">
      <input type="checkbox" :value="value" :checked="value" @change="updateInput" />
      <span class="slider"></span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  computed: {},

  methods: {
    updateInput(event) {
      const isChecked = event.target.checked;
      this.$emit('input', isChecked);
    },
  },
};
</script>

<style lang="scss">
.switch-container {
  position: relative;
  display: grid;
  place-items: center;
  width: fit-content;
}

.switch {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 15px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  border: solid 2px var(--gray-color-700);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 7px;
  width: 7px;
  left: 3px;
  bottom: 2px;
  background-color: var(--gray-color-700);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked {
  & + .slider:before {
    background-color: var(--main-color-500);
  }
  & + .slider {
    // background-color: var(--main-color-500);
    border: solid 2px var(--main-color-500);
  }
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(8px);
  -ms-transform: translateX(8px);
  transform: translateX(8px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 24px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
