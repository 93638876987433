<template>
  <Scatter :chart-options="chartOptions" :chart-data="chartData" :width="width" :height="height" />
</template>

<script>
import { Scatter } from 'vue-chartjs/legacy';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  PointElement,
  LinearScale,
} from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, PointElement, LinearScale);

export default {
  name: 'ScatterChart',
  components: {
    Scatter,
  },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    chartOptions: {
      type: Object,
      default: () => {},
    },
    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {};
  },
};
</script>
