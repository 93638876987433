<template>
  <Modal ref="modal__config" size="sm" @close="configOptions = { ...company.salaryEquityCongif }">
    <template #title>Configuración</template>
    <template #content>
      <validation-observer tag="div" v-slot="{ invalid }" class="form__validation">
        <div class="tabs__container">
          <Tabs
            :tabList="[
              { title: 'Data', icon: 'percentage' },
              { title: 'Puestos', icon: 'suitcase' },
              { title: 'Empleados', icon: 'user' },
            ]"
            @tabChange="currentTab = $event"
          >
            <template v-slot:tabPanel-1>
              <form>
                <div class="form__content">
                  <div>
                    <span>
                      <h5>Superior</h5>
                      <p>Por encima de la estructura salarial interna.</p>
                    </span>
                    <span>
                      <UnitInput
                        unit="%"
                        name="Superior"
                        :rules="{
                          required: true,
                        }"
                        :value="configOptions.max"
                        @input="configOptions.max = $event"
                      />
                    </span>
                  </div>
                  <div>
                    <span>
                      <h5>Mínimo</h5>
                      <p>Por debajo de la estructura salarial interna.</p>
                    </span>
                    <span>
                      <UnitInput
                        unit="%"
                        name="Mínimo"
                        :rules="{
                          required: true,
                        }"
                        :value="configOptions.min"
                        @input="configOptions.min = $event"
                      />
                    </span>
                  </div>
                  <div>
                    <span>
                      <h5>Múltiplo</h5>
                      <p>
                        Multiplica la cantidad de sueldo a visualizar.
                      </p>
                    </span>
                    <span>
                      <custom-input
                        v-model="configOptions.multiplier"
                        type="number"
                        rules="required"
                      />
                    </span>
                  </div>
                </div>
              </form>
            </template>
            <template v-slot:tabPanel-2>
              <UpdatePositionModal @close="closeModal" ref="modal__position" />
            </template>
            <template v-slot:tabPanel-3>
              <form>
                <div class="form__content">
                  <div>
                    <span>
                      <h5>Superior</h5>
                      <p>Porcentaje que está por encima de la mediana.</p>
                    </span>
                    <span>
                      <UnitInput
                        unit="%"
                        name="Superior"
                        :rules="{
                          required: true,
                        }"
                        :value="configOptions.posMax"
                        @input="configOptions.posMax = $event"
                      />
                    </span>
                  </div>
                  <div>
                    <span>
                      <h5>Mínimo</h5>
                      <p>Porcentaje que está por debajo de la mediana.</p>
                    </span>
                    <span>
                      <UnitInput
                        unit="%"
                        name="Mínimo"
                        :rules="{
                          required: true,
                        }"
                        :value="configOptions.posMin"
                        @input="configOptions.posMin = $event"
                      />
                    </span>
                  </div>
                </div>
              </form>
            </template>
          </Tabs>
        </div>
        <div class="modal__buttons">
          <Button @click="onSave" type="submit" :disabled="invalid" size="medium" variant="primary">
            {{ isLoading ? 'Guardando...' : 'Guardar' }}
          </Button>
        </div>
      </validation-observer>
    </template>
  </Modal>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import { ValidationObserver } from 'vee-validate';

import CustomInput from '@/components/custom/CustomInput.vue';
import Button from '@/components/buttons/Button.vue';
import UnitInput from '@/components/custom/UnitInput.vue';
import Modal from '@/components/Modal.vue';
import Tabs from '@/components/Tabs.vue';
import UpdatePositionModal from './UpdatePositionModal.vue';

export default {
  components: {
    ValidationObserver,
    Modal,
    Button,
    CustomInput,
    UnitInput,
    Tabs,
    UpdatePositionModal,
  },
  data() {
    return {
      isLoading: false,
      configOptions: {},
      currentTab: 'Data',
    };
  },
  methods: {
    ...mapMutations(['setAlert']),
    ...mapActions(['updateCompany']),

    closeModal() {
      this.$refs.modal__config.close();
    },

    open() {
      this.$refs.modal__config.open();
    },

    async onSave() {
      this.isLoading = true;
      if (this.currentTab !== 'Puestos') {
        try {
          await this.updateCompany({ id: this.company.id, salaryEquityCongif: this.configOptions });
          this.$emit('update');
          this.closeModal();
          this.setAlert({
            state: 'success',
            message: 'Empresa actualizada',
          });
        } catch (e) {
          console.log(e);
          this.setAlert({
            state: 'error',
            message: 'Ocurrió un error, por favor inténtelo nuevamente',
          });
        }
      } else {
        await this.$refs.modal__position.onSave();
      }
      this.isLoading = false;
    },
  },

  computed: {
    ...mapState({
      company: (state) => state.company,
    }),
  },

  async mounted() {
    try {
      this.isLoading = true;
      this.configOptions = { ...this.company.salaryEquityCongif };
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style lang="scss">
.employee__tabs {
  flex-grow: 1;
}
.tabs__container {
  padding: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}
.form__content {
  display: flex;
  flex-flow: column;
  gap: 1rem;
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    &:not(:last-child) {
      border-bottom: solid 2px var(--gray-color-200);
      padding-bottom: 1rem;
    }
    & > span:nth-child(2) {
      width: 80.21px;
      .field {
        height: 36.8px;
      }
    }
  }
}
</style>
